<template>
  <div :style="backCss" class="timepicker">
    <v-touch @swipeleft="getNextMonthDayList" @swiperight="getPreMonthDayList" class="wrapper">
      <div class="timepicker-box">
        <div class="box-section">
          <div class="section-slider">
            <div class="slider-year-month">
              <div class="slider-add">
                <!-- <a-icon @click="changeViewType" type="calendar" /> -->
                <a-button v-if="radioValue == 2" @click="addEvent" type="primary" size="small">新的备忘 </a-button>
              </div>
              <a-icon @click="getPreMonthDayList" type="left" class="pre" />
              <span class="year" style="font-size:18px;">{{ this.monthTitle }}</span>
              <a-icon @click="getNextMonthDayList" type="right" class="next" />
              <div class="slider-add">
                <!-- <a-icon v-if="!isAccountCenter" @click="showDrawer" type="search" /> -->
              </div>
            </div>

            <div class="slider-week">
              <span class="week-item" :key="key" v-for="(item, key) in weekText">{{ item }}</span>
            </div>
            <div class="slider-day">
              <div
                :class="['day-item', item.isThisMonth ? '' : 'not-current-month']"
                :key="key"
                v-for="(item, key) in days"
                @click="changeSelItem(item, key)"
              >
                <div
                  :class="[
                    'day',
                    item.isToDay ? 'day-item-current' : '',
                    selIndex == key ? 'day-item-sel' : '',
                    item.propsAttr.length ? 'sel' : ''
                  ]"
                >
                  {{ item.day }}
                  <span :class="getHoliday(item) == 'HOLIDAY' ? 'holiday-span-orange' : 'holiday-span-red'">
                    {{ getHoliday(item) == 'HOLIDAY' ? '假' : getHoliday(item) == 'WORK' ? '班' : '' }}
                  </span>
                  <div style="width:100%;display:flex;justify-content:center;">
                    <div
                      :class="
                        mobileTasks
                          .filter(i => i.deadline.split(' ')[0] == item.data)
                          .findIndex(i => i.taskStatus == '可开始') > -1
                          ? 'is-no-finish-task'
                          : ''
                      "
                    ></div>
                    <div
                      :class="
                        mobileTasks
                          .filter(i => i.deadline.split(' ')[0] == item.data)
                          .findIndex(i => i.taskStatus == '驳回') > -1
                          ? 'is-reject-task'
                          : ''
                      "
                    ></div>
                    <div
                      :class="
                        mobileTasks
                          .filter(i => i.deadline.split(' ')[0] == item.data)
                          .findIndex(i => i.taskStatus == '等待') > -1
                          ? 'is-have-task'
                          : ''
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-touch>
    <a-spin :spinning="mobileLoading">
      <div class="radio-list">
        <div class="radio-div">
          <span
            @click="changeRadio(1)"
            :style="
              radioValue == 1
                ? 'color:' + color + ';border-color:' + color
                : 'border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)'
            "
            >任务</span
          >
          <span
            @click="changeRadio(2)"
            :style="
              radioValue == 2
                ? 'color:' + color + ';margin-left:20px;border-color:' + color
                : 'margin-left:20px;border-color:rgba(0,0,0,0);color:rgba(0,0,0,0.65)'
            "
            >备忘</span
          >
        </div>
        <div>
          <a-icon @click="reload" style="margin-right:8px" type="reload" />
          <span @click.stop="goToMytask" class="iconfont icon-xiao21 icon"></span>
        </div>
      </div>
      <div class="mobile-task-list">
        <template v-if="radioValue == 1">
          <div
            @click="toTaskDetail(item)"
            v-for="item in mobileTasks.filter(item => item.deadline.split(' ')[0] == selDay.data)"
            :key="item.id"
          >
            {{
              (item.productSequenceNo ? item.productSequenceNo + '-' : '') +
                item.productionName +
                '-' +
                item.chapterSequenceNo +
                '-' +
                item.stageName +
                '-' +
                item.user.userName
            }}
            <actions-tasks
              v-if="item"
              :isMyTask="isMyTask"
              @openTaskModel="openTaskModel"
              :isCalendar="true"
              :isMobile="true"
              :loadData="reload"
              :propsParams="item"
            ></actions-tasks>
          </div>
        </template>
        <template v-else>
          <div v-for="item in mobileEvents" style="width:100%;margin-bottom: 5px;padding-right:19px" :key="item.id">
            <div :style="'display: flex; justify-content: space-between;width:100%;background-color:#f8f8f8'">
              <!-- getBack({deadline:item.end}) -->
              <div
                style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;width:100%;padding-left:5px"
              >
                <input
                  type="radio"
                  style="position: relative;top: 2px;left: 3px;"
                  :checked="item.checked == 'YES' ? true : false"
                  :id="item.id"
                  @click="
                    e => {
                      radioDayClick(e, item)
                    }
                  "
                />
                <label
                  :for="item.id"
                  :style="
                    item.checked == 'YES'
                      ? 'text-decoration:line-through;position: relative;top: -2px;left: 8px;'
                      : 'position: relative;top: -2px;left: 8px;width:100%'
                  "
                  ><span
                    style=";overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 95%;display:inline-block;position:relative;top:4px;"
                  >
                    {{ item.eventData }}</span
                  ></label
                >
              </div>
              <div style="margin-right: 4px" @click="removeEvent(item)">
                <a-icon class="delete-icon" style="position: relative;top: 1px" type="delete" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </a-spin>
    <a-modal title="添加事件" :visible="addEventDlgShow" @ok="handleAddEventOk" @cancel="handleAddEventCancel">
      <p>
        <a-textarea autoSize v-model="addEventText"></a-textarea>
      </p>
    </a-modal>
    <tasks-model ref="tasksModel" @closeTasksModel="closeTasksModel" />
  </div>
</template>
<script>
import { formatDate, returnData, getFirstAndLast, isToday } from '@/views/modules/components/day.js'
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasks'
import moment from 'moment'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
import tasksModel from '@/views/system/modules/mytasks/modules/tasksModel'
export default {
  name: 'MobileCalendar',
  components: {
    ActionsTasks,
    tasksModel
  },
  props: {
    //有任务的日期
    //dataList: {
    //	require: false,
    //	type: Array,
    //	default: () => []
    //}
    /*     holiday: {
      type: Array,
      default: () => []
    }, */
    schedule: {
      type: Array,
      default: () => []
    },
    stageList: {
      type: Array,
      default: () => []
    },
    mobileTasks: {
      type: Array,
      default: () => []
    },
    mobileEvents: {
      type: Array,
      default: () => []
    },
    mobileLoading: {
      type: Boolean,
      default: false
    },
    isMyTask: {
      type: Boolean,
      default: false
    },
    isAccountCenter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addEventDlgShow: false,
      radioValue: 1,
      holiday: [],
      stageIds: [],
      productionName: '',
      settlementMonth: '',
      mobileViewType: 'taskList',
      selDay: {},
      weekText: ['日', '一', '二', '三', '四', '五', '六'],
      currentDay: '', //当前几号
      currentYear: '', //当前年
      currentMonth: '', // 当前月
      currentWeek: '', // 当前月1号是星期几？ 0表示星期天
      monthTitle: '',
      dataList: [
        {
          bgcolor: '#67b7f0',
          id: 1180,
          className: '二级建造师考试',
          data: '2022-06-26'
        }
      ], //有任务的日期
      thisMonthDay: [],
      days: [], //所有日期
      firstDay: new Date(),
      weekDay: new Date().getDay(), //获取今天是周几
      selIndex: -1,
      visible: false,
      color: '',
      addEventText: ''
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  created() {
    this.firstDay.setDate(this.firstDay.getDate() - this.weekDay) //获取本周第一天的日期
    this.initData()
  },
  mounted() {
    this.color = this.$store.state.app.color
  },
  methods: {
    moment,
    closeTasksModel() {
      this.reload()
      this.$emit('getAllData')
    },
    openTaskModel(data) {
      this.$refs.tasksModel.open(data.noUpdateTasks, data.hasFeedback)
    },
    addEvent() {
      this.addEventDlgShow = true
    },
    handleAddEventCancel() {
      this.addEventDlgShow = false
      this.addEventText = ''
    },
    handleAddEventOk() {
      this.addEventDlgShow = false
      postAction('/tasks/add_custom_event', {
        startDate: this.selDay.data,
        endDate: this.selDay.data,
        eventData: this.addEventText,
        checked: 'NO'
      }).then(({ success, data }) => {
        if (success) {
          this.reload()
          this.addEventText = ''
        }
      })
    },
    removeEvent(event) {
      deleteAction('/tasks/delete_custom_event', { id: (event.id + '').replace('custom_event_', '') }).then(
        ({ success }) => {
          if (success) {
            this.reload()
          }
        }
      )
    },
    radioDayClick(e, item) {
      if (item.checked == 'YES') {
        item.checked = 'NO'
        e.target.checked = false
      } else {
        item.checked = 'YES'
        e.target.checked = true
      }
      putAction(`/tasks/change_custom_event?id=${item.id}&checked=${item.checked}`).then()
    },
    goToMytask() {
      this.$router.push('/system/mytasks')
    },
    changeRadio(value) {
      this.radioValue = value
      this.reload()
    },
    changeFirstAndLastDay(startDate, endDate) {
      this.getHolidays(startDate, endDate)
    },
    async getHolidays(startDate, endDate) {
      const res = await getAction('/holiday', {
        startDate,
        endDate
      })
      this.holiday = res.data
    },
    searchQueryForm() {
      this.$emit('clickCurrentDaySearch', {
        days: this.days,
        stageIds: this.stageIds,
        productionName: this.productionName,
        settlementMonth: this.settlementMonth
      })
    },
    onClose() {
      this.visible = false
    },
    showDrawer() {
      this.visible = true
    },
    toTaskDetail(record) {
      this.$router.push({
        path: `/translate/mytasks/detail/${record.id}/${record.productionId}/${
          record.chapterId
        }?record=${encodeURIComponent(
          JSON.stringify({
            platformId: record.platformId
          })
        )}&isMyTask=${this.isMyTask}&name=${record.productionName + '-' + record.chapterOrder + '-' + record.stageName}`
      })
    },
    changeViewType() {
      if (this.mobileViewType == 'taskList') {
        this.mobileViewType = 'eventList'
      } else {
        this.mobileViewType = 'taskList'
      }
    },
    getHoliday(item) {
      let text = ''
      text = this.holiday?.find(i => {
        return item.data == i.date
      })
        ? this.holiday.find(i => {
            return item.data == i.date
          }).type
        : ''
      return text
    },
    reload() {
      this.$emit('clickCurrentDay', { ...this.selDay, days: this.days, viewType: this.radioValue })
    },
    getSchedule(item) {
      let data = 0
      data = this.schedule.find(i => {
        return item.data.replaceAll('-', '') == i.key
      })
        ? this.schedule.find(i => {
            return item.data.replaceAll('-', '') == i.key
          }).data
        : 0
      return data && data.length > 0 ? true : false
    },
    // 选中日期
    changeSelItem(item, index) {
      this.selIndex = index
      this.selDay = item
      if (this.radioValue == 2) {
        this.$emit('clickCurrentDay', { ...item, days: this.days, viewType: this.radioValue })
      }
    },
    // 下月
    getNextMonthDayList() {
      this.pickNext(this.currentYear, this.currentMonth)
      this.$emit('clickCurrentDay', { ...this.selDay, days: this.days, viewType: this.radioValue })
    },
    // 上月
    getPreMonthDayList(a, b, c) {
      console.log(a, b, c)
      this.pickPre(this.currentYear, this.currentMonth)
      this.$emit('clickCurrentDay', { ...this.selDay, days: this.days, viewType: this.radioValue })
    },
    // 上一月 （@param year, @param month）
    pickPre(year, month) {
      console.log('上一月')
      var d = new Date(formatDate(year, month, 1))
      d.setDate(0) //设置为上个月
      this.initData(formatDate(d.getFullYear(), d.getMonth() + 1, 1))
      this.$emit('currentYM', {
        year: this.currentYear,
        month: this.currentMonth
      })
    },
    // 下一个月 （@param year, @param month）
    pickNext(year, month) {
      console.log('下一个月')
      var d = new Date(formatDate(year, month, 1))
      d.setDate(35)
      this.initData(formatDate(d.getFullYear(), d.getMonth() + 1, 1))
      this.$emit('currentYM', {
        year: this.currentYear,
        month: this.currentMonth
      })
    },
    // 初始化月
    initData(cur) {
      var date
      if (cur) {
        // 切换上一月、下一月
        date = new Date(cur)
      } else {
        var now = new Date() // 此处取本机时间，应改为服务器时间
        // console.log(now.getFullYear(), now.getMonth() + 1, 1)
        var date = new Date(formatDate(now.getFullYear(), now.getMonth() + 1, 1))
      }

      this.currentDay = new Date().getDate() // 今日日期 几号
      this.currentYear = date.getFullYear() // 当前年份
      this.currentMonth = date.getMonth() + 1 // 当前月份
      this.currentWeek = date.getDay() // 当前月1号是星期几？ 0表示星期天
      // console.log(this.currentYear, this.currentMonth)
      var month = this.currentMonth >= 10 ? this.currentMonth : '0' + this.currentMonth
      this.monthTitle = this.currentYear + '-' + month
      // 当前月最后一天是星期几？ 0表示星期天
      var nextWeek = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDay()
      var str = formatDate(this.currentYear, this.currentMonth, 1) // 2021/05/01
      var nextStr = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString() // 2021/05/31
      var daysList = [] // 初始化日期
      // 设置上一个月 需显示 的最后几天  铺满一周
      for (var i = this.currentWeek; i > 0; i--) {
        var d = new Date(str)
        d.setDate(d.getDate() - i)
        var tod = returnData(d) //获取完整日期
        var dayobject = {
          d: tod,
          data: tod,
          day: d.getDate(),
          isThisMonth: d.getMonth() + 1 == this.currentMonth ? true : false,
          isToDay: isToday(tod),
          isSign: this.isVerDate(d),
          propsAttr: this.copyAttr(d, this.dataList)
        } // 用一个对象包装Date对象  以便为以后预定功能添加属性
        daysList.push(dayobject) // 将日期放入data 中的days数组 供页面渲染使用
      }
      // 显示当前月的天数  第二个循环为 j<= 36- currentWeek，
      // 因为1号是星期六的时候当前月需显示6行，如2020年8月
      var num = 0 //第几个月 每遇到1号加1
      for (var j = 0; j <= 36 - this.currentWeek; j++) {
        var d = new Date(str)
        d.setDate(d.getDate() + j)
        var tod = returnData(d) //获取完整日期
        var dddd = d.getDate()
        var dayobject = {
          d: tod,
          data: tod,
          day: d.getDate(),
          isThisMonth: d.getMonth() + 1 == this.currentMonth ? true : false,
          isToDay: isToday(tod),
          isSign: this.isVerDate(d),
          propsAttr: this.copyAttr(d, this.dataList)
        }
        if (dddd == 1) {
          num++
        }
        if (num == 2) {
          break
        }
        daysList.push(dayobject)
      }
      // 设置下一个月 需显示 的最前几天铺满一周
      for (var k = 1; k <= 6 - nextWeek; k++) {
        var d = new Date(nextStr)
        d.setDate(d.getDate() + k)
        var tod = returnData(d) //获取完整日期
        var dayobject = {
          d: tod,
          data: tod,
          day: d.getDate(),
          isThisMonth: d.getMonth() + 1 == this.currentMonth ? true : false,
          isToDay: isToday(tod),
          isSign: this.isVerDate(d),
          propsAttr: this.copyAttr(d, this.dataList)
        } // 用一个对象包装Date对象  以便为以后预定功能添加属性
        daysList.push(dayobject) // 将日期放入data 中的days数组 供页面渲染使用
      }
      // 设置本月的所有日期
      this.thisMonthDay = daysList.filter((item, index) => {
        if (isToday(item.d)) {
          this.selIndex = index
        }
        return item.isThisMonth
      })
      this.days = daysList
      this.selDay =
        this.days.find(item => {
          return item.isToDay
        }) || this.days[0]
      if (
        !this.days.find(item => {
          return item.isToDay
        })
      ) {
        this.selIndex = 0
      }
      this.$emit('clickCurrentDay', {
        ...this.days.find(item => {
          return item.isToDay
        }),
        days: this.days,
        viewType: this.radioValue
      })
      // console.log(daysList)
    },
    // 判断该日期是否有任务
    isVerDate(d) {
      let dda = returnData(d)
      this.signdays = []
      for (var i in this.sign_Month) {
        this.signdays.push(this.sign_Month[i].data)
      }
      return this.signdays.includes(dda)
    },
    // 复制元素的所有属性
    copyAttr(d, List, type) {
      // 日期   原数组
      var dd = null
      if (type) {
        dd = d
      } else {
        dd = returnData(d)
      }
      var ArrayList = []
      if (List.length > 0) {
        List.forEach(item => {
          if (item.data == dd) {
            // item.tt = getHourAndMin(item.start) + '-' + getHourAndMin(item.end)
            ArrayList.push(item)
          }
        })
        return ArrayList
      } else {
        return []
      }
    }
  }
}
</script>
<style lang="less" scoped>
.radio-list {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  .radio-div {
    span {
      padding-bottom: 5px;
      border-bottom: 2px solid rgba(0, 0, 0, 0);
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
    }
  }
}
/deep/ .wrapper {
  touch-action: pan-y !important;
}
.mobile-task-list {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  overflow-y: auto;
  max-height: 120px;
  > div {
    background-color: rgb(248, 248, 248);
    margin-top: 8px;
    padding-left: 19px;
    padding-right: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
.timepicker {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  .timepicker-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #fff;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    .box-section {
      position: relative;
      flex: 1;

      .section-slider {
        display: flex;
        flex-direction: column;

        .slider-year-month {
          padding-top: 6px;
          padding-bottom: 6px;
          color: var(--theme-color);
          display: flex;
          align-items: center;
          justify-content: space-between;

          .pre {
            width: 20px;
            height: 20px;
            line-height: 24px;
          }

          .next {
            width: 20px;
            height: 20px;
            line-height: 24px;
          }
          .slider-add {
            width: 14.28%;
            text-align: center;
          }
        }
        .slider-week {
          display: flex;
          align-items: center;
          color: #333;

          .week-item {
            flex: 1;
            font-size: 14px;
            padding: 7px 0 5px;
            text-align: center;
          }
        }

        .slider-day {
          padding: 5px 0;
          display: flex;
          flex-wrap: wrap;
          .day-item {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #333;
            width: 14.28%;
            height: 40px;
            text-align: center;
            font-size: 14px;

            .day {
              width: 20px;
              line-height: 20px;
              height: 20px;
              border-radius: 5px;
              position: relative;
            }
            .holiday-span-red {
              font-size: 12px;
              position: absolute;
              top: -8px;
              right: -11px;
              color: red;
              transform: scale(0.7);
            }
            .holiday-span-orange {
              font-size: 12px;
              position: absolute;
              right: -11px;
              top: -8px;
              color: rgb(251, 172, 0);
              transform: scale(0.7);
            }
            .is-have-task {
              width: 5px;
              height: 5px;
              margin-left: 1px;
              border-radius: 50%;
              margin-top: 3px;
              background-color: rgba(0, 0, 0, 0.25);
            }
            .is-reject-task {
              width: 5px;
              margin-left: 1px;
              height: 5px;
              border-radius: 50%;
              margin-top: 3px;
              background-color: orange;
            }
            .is-no-finish-task {
              width: 5px;
              height: 5px;
              border-radius: 50%;
              margin-left: 1px;
              margin-top: 3px;
              background-color: red;
            }
            .is-finish-task {
              width: 5px;
              margin-left: 1px;
              height: 5px;
              border-radius: 50%;
              margin-top: 3px;
              background-color: green;
            }
            .sel:after {
              position: absolute;
              display: block;
              content: '';
              width: 3px;
              height: 3px;
              margin: auto;
              border-radius: 50%;
              background: #677fd2;
              left: 0;
              right: 0;
              bottom: 0;
            }
          }

          .day-item-current {
            color: #fff !important;
            background-color: var(--theme-color);
          }

          .day-item-sel {
            border: 1px solid var(--theme-color);
            color: var(--theme-color);
          }

          .not-current-month {
            .day {
              color: #c9c6c6;
            }
          }
        }
      }
    }

    .box-footer {
      flex-basis: 10%;
      display: flex;
      box-shadow: 0px -1px 0px #eee;

      .footer-btn {
        flex: 1;
        text-align: center;

        span {
          font-size: 14px;
        }

        &:not(:last-child) {
          box-shadow: 1px 0px 0px #eee;
        }
      }
    }
  }
}
.app-list .timepicker {
  padding-left: 20px !important;
}
input[type='radio'] + label::before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid #999999;
  box-sizing: border-box;
  position: relative;
  top: -2px;
  left: -5px;
}

input[type='radio']:checked + label::before {
  background-color: var(--theme-color);
  background-clip: content-box;
  border: 1px solid var(--theme-color);
  padding: 0.1rem;
  box-sizing: border-box;
}
input[type='radio'] {
  display: none;
}
.mobile-task-list::-webkit-scrollbar-button {
  height: 0;
}
.mobile-task-list::-webkit-scrollbar {
  border-radius: 0;
  width: 3px;
}
.mobile-task-list::-webkit-scrollbar-track {
  border-radius: 0;
}
.mobile-task-list::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.mobile-task-list::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.icon-xiao21 {
  cursor: pointer;
  font-weight: 700 !important;
  &:hover {
    color: var(--theme-color) !important;
  }
}
</style>
